<template>
  <div>
    <vs-popup class="sm:popup-w-50" :title="btnText + ' SKUP'" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Tgl</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-calendar" v-model="data.tgl" disabled/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Status</label>
            <vs-select v-model="data.status" class="w-full" disabled>
              <vs-select-item v-for="(it, index) in listStatus" :key="index" :value="it.value" :text="it.name"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-3" v-if="isShowFormInputTglBayarBooFee">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Tgl. Bayar Booking Fee *</label>
            <flat-pickr class="w-full" icon-pack="feather" icon="icon-calendar" v-model="data.tgl_bayar_booking_fee"></flat-pickr>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Cara Bayar Booking Fee *</label>
            <vs-select class="w-full" v-model="data.id_cara_bayar_bf">
              <vs-select-item v-for="(it, index) in caraBayars" :key="index" :value="it.id" :text="it.nama"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-3" v-if="isShowFormInputBuktiTransfer">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Bukti Transfer * <span class="text-xs opacity-75" v-if="isCompressingBuktiTransfer">(compressing...)</span></label>
            <vs-input class="w-full" type="file" accept="image/*" v-model="data.buktiTransferTemp" @change="onSelectedBuktiTransfer"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-textarea class="w-full mb-0" v-model="data.keterangan"/>
          </div>
        </div>
        <div class="vx-row mb-3" v-if="isShowFormInputSignature">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Signature *</label>
            <div>
              <img v-if="data.signature.dataUrl" alt="Signature" class="h-64 w-auto bg-white border border-grey border-solid" :src="data.signature.dataUrl"/>
              <vs-button @click="isShowSignaturePad = true" class="mt-1 px-3" size="small" icon-pack="feather" icon="icon-edit-2">{{ data.signature.dataUrl ? 'Retake Signature' : 'Add Signature' }}</vs-button>
            </div>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" :color="btnColor" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading || isCompressingBuktiTransfer" :color="btnColor" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">{{ btnText }}</span>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <!--modals-->
    <SignaturePad :isActive.sync="isShowSignaturePad" @saved="onSignatureSaved"/>

  </div>
</template>

<script>
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import SkupApprovalRepository from '@/repositories/approvals/skup-approval-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import moment from 'moment'
import { compressImage, convertToFormData } from '@/utilities/common/global-methods'
import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'

export default {
  name: 'SkupApprovalSigning',
  props: ['isActive', 'status'],
  components: {
    flatPickr,
    ValidationErrors,
    SignaturePad: () => import('@/views/components/signature-pad/SignaturePad')
  },
  computed: {
    listStatus () {
      return [{ name: 'APPROVE', value: 'DITERIMA' }, { name: 'REVISI', value: 'DIREVISI' }, { name: 'REJECT', value: 'DITOLAK' }]
    },
    btnColor () {
      return this.status === 'DITERIMA' ? 'success' : (this.status === 'DIREVISI' ? 'warning' : (this.status === 'DITOLAK' ? 'danger' : null))
    },
    btnText () {
      return this.status === 'DITERIMA' ? 'Approve' : (this.status === 'DIREVISI' ? 'Revisi' : (this.status === 'DITOLAK' ? 'Reject' : null))
    },
    storeBookingDetail () {
      return this.$store.state.marketing.bookingDetail
    },
    booking () {
      return this.storeBookingDetail.booking
    },
    isShowFormInputTglBayarBooFee () {
      return this.status === 'DITERIMA' && (this.booking.current_step_skup === this.booking.skup_step_require_tgl_bayar_boofee)
    },
    isShowFormInputBuktiTransfer () {
      return this.status === 'DITERIMA' && (this.booking.current_step_skup === this.booking.skup_step_require_bukti_transfer)
    },
    isShowFormInputSignature () {
      return this.status === 'DITERIMA'
    }
  },
  data () {
    return {
      isLoading: false,
      isCompressingBuktiTransfer: false,
      isShowSignaturePad: false,
      isDataInited: false,
      errors: null,
      caraBayars: [],
      data: {
        tgl: moment().format('YYYY-MM-DD'),
        status: null,
        keterangan: null,
        tgl_bayar_booking_fee: null,
        id_cara_bayar_bf: null,
        bukti_transfer: null,
        signature: {
          blob: null,
          dataUrl: null
        }
      }
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        this.data.status = this.status

        if (!this.isDataInited) {
          this.getInitData()
        }
      }
    }
  },
  methods: {
    getInitData () {
      this.getCaraBayarBookingFee()
      this.isDataInited = true
    },

    async onSelectedBuktiTransfer (event) {
      const file = event.target.files[0]
      this.isCompressingBuktiTransfer = true
      const compressed = await compressImage(file)
      this.isCompressingBuktiTransfer = false
      this.data.bukti_transfer = compressed
    },

    onSignatureSaved (data) {
      this.data.signature.blob = data.blob
      this.data.signature.dataUrl = data.dataUrl
    },

    save () {
      this.errors = null
      this.isLoading = true

      // params
      const params = convertToFormData({
        id_booking: this.$route.params.idBooking,
        tgl: this.data.tgl,
        status: this.data.status,
        keterangan: this.data.keterangan,
        tgl_bayar_booking_fee: this.data.tgl_bayar_booking_fee,
        id_cara_bayar_bf: this.data.id_cara_bayar_bf,
        bukti_transfer: this.data.bukti_transfer,
        signature: this.data.signature.blob
      })

      SkupApprovalRepository.signing(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    getCaraBayarBookingFee () {
      const params = {}
      params.filter = JSON.stringify({ group1: 'BOOKING FEE' })
      CaraBayarRepository.getAll(params)
        .then(response => {
          this.caraBayars = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    onSuccess () {
      this.notifySuccess('Berhasil ditanggapi.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
      this.$store.dispatch('general/navbarBadge/getDataForMenuApproval')
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['caraBayars'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
